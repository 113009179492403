import { useEffect } from "react";

const Nct = () => {
  useEffect(() => {
    document.title = "MeTube - Nhạc của tui";
  }, []);

  return (
    <iframe
      src="https://nhaccuatui.com/"
      scrolling="yes"
      className="iframe-class"
      width="100%"
      height="925"
      frameBorder="0"
    ></iframe>
  );
};

export default Nct;
