import "../../css/listvideohome.css";
import { Link } from "react-router-dom";
import { Card, Col } from "antd";
import { useRecoilValue } from "recoil";
import { initLoading } from "../../recoil/initLoading";
const style = {
  background: "transparent",
  padding: "10px 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gridRowGap: "0.5rem",
  alignItems: "center",
};

const ListVideoHome = ({ data, theme }) => {

  const loading = useRecoilValue(initLoading);

  return (
    data &&
    data.map((item) => (
      <Col key={item.id} className="gutter-row" xs={24} sm={12} md={8} xl={6}>
        <Card loading={loading} style={{ width: "100%", background: "transparent", border: "none"}}>
          <Link to={"/watch/"+item.idvideo} style={style}>
            <img
              width="100%"
              src={"https://i.ytimg.com/vi/"+item.idvideo+"/hqdefault.jpg"}
              alt=""
            />
            <h4 className={`title-video-home ${theme ? "dark" : "light"}`}>
              {item.name}
            </h4>
          </Link>
      </Card>
      </Col>
    ))
  );
};

export default ListVideoHome;
