import { Row, Divider, Pagination } from "antd";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { list, listNavigation } from "../../api/videoAPI";
import ListVideoHome from "./ListVideoHome";
import Loading from "../global/Loading";
import { initLoading } from "../../recoil/initLoading";
import { initDataAtom } from "../../recoil/dataAtom";
import { initDataLength } from "../../recoil/dataLength";

const ContentHome = ({ theme }) => {
  const [dataLength, setDataLength] = useRecoilState(initDataLength);
  const active = useRecoilValue(initLoading);
  const [data, setData] = useRecoilState(initDataAtom);

  const onChange = (data) => {
    listNavigation(data).then(({ data }) => setData(data));
  };

  useEffect(() => {
    list().then((response) => setDataLength(response.data));
  }, []);
  return (
    <div style={{ margin: 10 }}>
      <Loading loading={active} />
      <Divider orientation="left" className={`title-home ${theme ? "dark" : "light"}`}>MUSIC HOT</Divider>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <ListVideoHome theme={theme} data={data} />
      </Row>
      <Pagination
        responsive
        defaultPageSize={8}
        style={{ textAlign: "center" }}
        onChange={onChange}
        total={dataLength.length}
        className={`pagination-theme ${theme ? "dark" : "light"}`}
      />
    </div>
  );
};
export default ContentHome;
