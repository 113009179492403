import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import ContentHome from "../../components/website/ContentHome";
import SlideHome from "../../components/website/SlideHome";

const HomeContainer = () => {
  const [theme, setTheme] = useOutletContext();
  useEffect(() => {
    document.title = "MeTube - Home";
  }, []);
  return (
    <div
      className="home"
      style={{ position: "relative", overflowY: "scroll", maxHeight: "90vh" }}
    >
      <SlideHome theme={theme} />
      <ContentHome theme={theme} />
    </div>
  );
};

export default HomeContainer;
