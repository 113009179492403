import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { isAuth } from "../../commons/images/auth/auth";

const PrivateCpanel = ({ children }) => {
  const auth = isAuth();
  if (!auth || auth.password != "trantien98") {
    return (
      <Result
        className="warning-cpanel"
        status="warning"
        title="Xin lỗi bạn không có quyền truy cập trang này!"
        extra={[
          <Button type="primary" key="gohome">
            <Link to="/">Go Home</Link>
          </Button>,
          <Button key="login">
            <Link to="/login">Login</Link>
          </Button>
        ]}
      />
    );
  }
  return children;
};

export default PrivateCpanel;
