import { Col, Divider, Input, message, Pagination, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { searchAPI, searchAPIAll } from "../../api/videoAPI";
import { checkDarkTheme, getTitle } from "../../commons/images/func/func";
const { Search } = Input;

const QueryPara = ({ title }) => {
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState([]);
  const [theme, setTheme] = useState(checkDarkTheme());

  useEffect(() => {
    checkDarkTheme() ? setTheme(true) : setTheme(false);
  }, []);
  useEffect(() => {
    getTitle(title);
  });

  const onSearch = (value) => {
    if (value.length > 0) {
      searchAPI(value, 1)
        .then(({ data }) => {
          setData(data);
        })
        .then(() => {
          searchAPIAll(value).then(({ data }) => {
            data <= 0 && message.error("không có kết quả nào được tìm thấy!");
            setDataLength(data);
          });
        });
    } else {
      message.warning("Vui lòng nhập từ khóa cần tìm kiếm!");
    }

    console.log("Dữ liệu trả về", data);
  };

  // const onChange = (id) => {
  //   searchAPI(data, id).then((response) => {
  //     setData(response.data);
  //     console.log(id,data);
  //   });
  // };

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (idvideo, name) => (
        <Link to={"/watch/" + name.idvideo}>{name.name}</Link>
      ),
    },
    {
      dataIndex: "idvideo",
      key: "idvideo",
      render: (idvideo) => (
        <img
          src={"https://i.ytimg.com/vi/" + idvideo + "/hqdefault.jpg"}
          width="120px"
          alt=""
        />
      ),
    },
  ];

  return (
    <div style={{ margin: 10 }}>
      <Divider
        className={`title-search ${theme ? "dark" : "light"}`}
        orientation="center"
      >
        Tìm kiếm
      </Divider>
      <div>
        <Search
        className={`input-search ${theme ? "dark" : "light"}`}
          placeholder="Nhập từ khóa cần tìm kiếm"
          onSearch={onSearch}
          enterButton
        />
      </div>
      {dataLength.length > 0 && (
        <div>
          <h3 style={{ textAlign: "center", marginTop: "10px" }}>
            Có {dataLength.length} kết quả được tìm thấy!
          </h3>
          <Table
            pagination={{ pageSize: 8 }}
            columns={columns}
            dataSource={data}
          />
        </div>
      )}
    </div>
  );
};

export default QueryPara;
