import "../../css/listvideohome.css";
import { Link } from "react-router-dom";
import { Col } from "antd";
const style = {
  background: "transparent",
  padding: "10px 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gridRowGap: "0.5rem",
  alignItems: "center",
};

const ListVideoWatch = ({ data, theme}) => {
  return (
    data &&
    data.map((item) => (
      <Col key={item.id} className="gutter-row" span={24} style={{padding: "0 1rem"}}>
        <Link to={"/watch/"+item.idvideo} style={style}>
          <img
            width="100%"
            src={"https://i.ytimg.com/vi/"+item.idvideo+"/hqdefault.jpg"}
            alt=""
          />
          <h4 className={`title-video-home ${theme ? "dark" : "light"}`}>
            {item.name}
          </h4>
        </Link>
      </Col>
    ))
  );
};

export default ListVideoWatch;
