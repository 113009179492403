import { useEffect } from "react";
import AllVideo from "./AllVideo";

const CpanelContainer = () => {

  useEffect(() => {
    document.title = "MeTube - Cpanel";
  }, []);

  return (
    <div
      className="cpanel"
      style={{ position: "relative", overflowY: "scroll", maxHeight: "90vh" }}
    >
        <AllVideo />
    </div>
  );
};

export default CpanelContainer;
