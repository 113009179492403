import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import Foot from "../components/website/Foot";
import Head from "../components/website/Head";
import MenuCpanel from "../components/cpanel/MenuCpanel";
import { useRecoilValue } from "recoil";
import { initInfoAdmin } from "../recoil/infoAdmin";
const { Sider, Content } = Layout;

const LayoutCpanel = () => {
  const infoadmin = useRecoilValue(initInfoAdmin);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Head infoadmin={infoadmin.username} />
      <Layout>
        <Sider className="menub" style={{ backgroundColor: "#FFFFFF", minHeight: "100vh"}}>
          <MenuCpanel />
          <Foot />
        </Sider>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutCpanel;
