import { useEffect } from "react";

const CoinMarket = () => {

  useEffect(() => {
    document.title = "MeTube - CoinMarket";
  }, []);

  return (
    <iframe
      src="https://coin68.com/"
      scrolling="yes"
      className="iframe-class"
      width="100%"
      height="925"
      frameBorder="0"
    ></iframe>
  );
};

export default CoinMarket;
