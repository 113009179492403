import { Button, Form, Input, Card, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "../../css/firefly.css";
import {
  CodeSandboxCircleFilled,
  GithubFilled,
  GoogleCircleFilled,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import moment from "moment";
import Loading from "../global/Loading";
import { isAuth } from "../../commons/images/auth/auth";
import { useSetRecoilState } from "recoil";
import { initInfoAdmin } from "../../recoil/infoAdmin";

const Login = () => {
  const [loading, setLoading] = useState("");
  const [checkTimeBG, setCheckTimeBG] = useState("light");
  const setInfoAdmin = useSetRecoilState(initInfoAdmin);

  let navigate = useNavigate();

  const successNotification = () => {
    notification.success({
      message: "Thông báo",
      description:
        "Đăng nhập thành công.",
    });
  };

  const errorNotification = (errorInfo) => {
    notification.error({
      message: "Thông báo",
      description:
        "Đăng nhập thất bại. =>"+errorInfo,
    });
  };

  const onFinish = (values) => {
    localStorage.setItem("isAuthMetube", JSON.stringify(values));
    let auth = isAuth();
    if (auth.password == "trantien98") {
      setInfoAdmin(auth);
      successNotification();
      navigate("/cpanel");
    }else{
      errorNotification("Tài khoản mật khẩu không chính xác");
    }
  };

  const onFinishFailed = (errorInfo) => {
    errorNotification("Vui lòng điền đầy đủ vào các trường")
  };

  useEffect(() => {
    const format = "hh:mm:ss";
    const time = moment(new Date().toLocaleString(), format),
      beforeTime = moment("18:00:00", format),
      afterTime = moment("24:00:00", format),
      beforeTimeDAWN = moment("00:00:00", format),
      afterTimeDAWN = moment("06:00:00", format);
      console.log(time);
    if (time.isBetween(beforeTime, afterTime) || time.isBetween(beforeTimeDAWN, afterTimeDAWN)) {
      setCheckTimeBG("night");
    } else {
      setCheckTimeBG("light");
    }
  }, []);

  return (
    <div className="login">
      <div className={`bg ${checkTimeBG}`}>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
        <div className="firefly"></div>
      </div>

      <Loading loading={loading} />

      <Card className="form-login">
        <h3 className="title">Đăng nhập</h3>
        <Form
          className="form-small"
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input className="input-login" placeholder="Please input your username!" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Vui lòng điền mật khẩu!" }]}
          >
            <Input.Password
              className="input-login"
              placeholder="Nhập mật khẩu của bạn"
            />
          </Form.Item>

          <Form.Item name="remember" valuePropName="checked">
            <Link to={"/"} style={{ float: "right" }}>
              Quên mật khẩu?
            </Link>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button className="btn btn-login" type="primary" htmlType="submit">
              Đăng nhập
            </Button>
          </Form.Item>

          <Form.Item className="or-login" wrapperCol={{ offset: 8, span: 16 }}>
            <span>Hoặc đăng nhập với</span>
            <div className="social">
              <a href={"#"}>
                <GoogleCircleFilled className="icon" />
              </a>
              <a href={"#"}>
                <GithubFilled className="icon" />
              </a>
              <a href={"#"}>
                <CodeSandboxCircleFilled className="icon" />
              </a>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
export default Login;