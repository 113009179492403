import '../../css/menub.css';
import { Menu, Spin, Switch } from "antd";
import {
  AppstoreAddOutlined,
  CompassOutlined,
  DashboardFilled,
  DingtalkOutlined,
  FileAddFilled,
  HomeOutlined,
  MenuOutlined,
  NodeExpandOutlined,
  PlaySquareOutlined,
  PoundCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

const handleClick = () => {
  document.querySelector('.menub').classList.toggle('active');
};

const MenuCpanel = () => {
  return (
    <Menu
      onClick={handleClick}
      style={{ minWidth: 100 }}
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      mode="inline"
    >
      <SubMenu key="sub1" icon={<MenuOutlined />} title="Menu">
          <Menu.Item key="1"><Link to="/cpanel"><DashboardFilled /> Dashboard </Link></Menu.Item>
          <Menu.Item key="2"><Link to="add"> <FileAddFilled /> Thêm Video </Link></Menu.Item>
          {/* <p className="title-menub"><AppstoreAddOutlined /> Dịch vụ khác</p>
          <Menu.Item key="4"><Link to="/news"><NodeExpandOutlined /> Tin nhanh</Link></Menu.Item>
          <Menu.Item key="5"><Link to="/coinmarket"><PoundCircleOutlined /> CoinMarket</Link></Menu.Item>
          <Menu.Item key="6"><Link to="/nct"><DingtalkOutlined /> Nhaccuatui</Link></Menu.Item> */}
      </SubMenu>
    </Menu>
  );
};

export default MenuCpanel;
