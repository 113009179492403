import { useEffect, useState } from "react";
import { list } from "../../api/videoAPI";
import "../../css/randomvideo.css";
import ListVideoWatch from "./ListVideoWatch";

const RandomVideo = ({ theme }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    list().then((response) => setData(response.data));
  }, []);

  return (
    <div
      className="list-video-watch"
      style={{ position: "relative", overflowY: "scroll", maxHeight: "100vh" }}
    >
      {/* <h2>Random Video</h2> */}
      <ListVideoWatch theme={theme} data={data} />
    </div>
  );
};

export default RandomVideo;
