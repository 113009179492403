import { Button, message, Popconfirm, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { list, remove } from "../../api/videoAPI";

const AllVideo = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    list().then(({ data }) => setData(data)).then(() => setLoading(false));
  }, []);

  const confirm = (id) => {
    remove(id)
    .then(() => {
        const newData = data.filter(data => data.id !== id);
        setData(newData);
    })
    .then(() => message.error('Bạn đã xóa!'))
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "idvideo",
      dataIndex: "idvideo",
      key: "idvideo",
    },
    {
      title: "Action",
      key: "id",
      render: (id) => (
        <Space size="middle">
          <Button type="primary" block>
            <Link to={"edit/"+id.id}>Sửa</Link>
          </Button>
          <Button type="primary" danger>
            <Popconfirm
              title="Bạn có chắc chắn xóa?"
              onConfirm={() => confirm(id.id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Xóa</a>
            </Popconfirm>
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <Table loading={loading} scroll dataSource={data} columns={columns} pagination={{ pageSize: 8 }} />
  );
};

export default AllVideo;
