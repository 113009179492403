import logo from "../../commons/images/logo.png";

import { Layout, Popconfirm, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import { useSetRecoilState } from "recoil";
import { initInfoAdmin } from "../../recoil/infoAdmin";

const { Header } = Layout;

const Head = ({ infoadmin, theme }) => {
  const clearInfo = useSetRecoilState(initInfoAdmin);
  let navigation = useNavigate();
  const menuShow = () => {
    document.querySelector(".menub").classList.toggle("active");
  };

  const logout = () => {
    message.warning('Bạn đã đăng xuất');
    localStorage.removeItem("isAuthMetube");
    clearInfo('');
    navigation("/");
  };
  
  return (
    <Header className={`header ${theme ? "theme-dark" : "theme-light"}`} style={{ backgroundColor: "#FFFFFF" }}>
      <Link to="/">
        <img width="80px" src={logo} alt="" />
      </Link>

      <MenuOutlined className="menu-outlined" onClick={menuShow} />
      {infoadmin ? <div className="info-admin">
         Xin chào! <Link to="/cpanel">{infoadmin}</Link> 
        {" "}
        <Popconfirm
          title="Bạn có muốn đăng xuất không?"
          onConfirm={logout}
          okText="Yes"
          cancelText="No"
        >
          <LogoutOutlined />
        </Popconfirm>
      </div> : null}
    </Header>
  );
};

export default Head;
