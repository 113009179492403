import Slider from "react-slick";
import "../../css/slidehome.css";

const SlideHome = ({ theme }) => {
  const settings = {
    className: `oneslide ${theme ? "dark" : "light"}`,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div style={{ margin: "10px 5px" }}>
      <Slider {...settings}>
        <div>
          <h3>Nhạc tết</h3>
        </div>
        <div>
          <h3>Nhạc remix</h3>
        </div>
        <div>
          <h3>Hài Tết</h3>
        </div>
        <div>
          <h3>Crypto news</h3>
        </div>
        <div>
          <h3>Thời sự</h3>
        </div>
        <div>
          <h3>VTV24</h3>
        </div>
        <div>
          <h3>Nhạc tết</h3>
        </div>
        <div>
          <h3>Nhạc remix</h3>
        </div>
        <div>
          <h3>Hài Tết</h3>
        </div>
        <div>
          <h3>Crypto news</h3>
        </div>
        <div>
          <h3>Thời sự</h3>
        </div>
        <div>
          <h3>VTV24</h3>
        </div>
      </Slider>
    </div>
  );
};
export default SlideHome;
