import instance from "./instance";

export const list = () => {
  const url = "/webvideo?sortBy=id&order=desc";
  return instance.get(url);
};

export const searchAPI = (dataSearch,idNav) => {
  const url = "/webvideo?search="+dataSearch+"&page="+idNav+"&limit=10";
  return instance.get(url);
};

export const searchAPIAll = (dataSearch) => {
  const url = "/webvideo?search="+dataSearch;
  return instance.get(url);
};

export const read = (id) => {
  const url = "/webvideo?idvideo=" + id;
  return instance.get(url);
};

export const readid = (id) => {
  const url = "/webvideo?id=" + id;
  return instance.get(url);
};

export const remove = (id) => {
  const url = "/webvideo/" + id;
  return instance.delete(url);
};

export const update = (data) => {
  const url = '/webvideo/'+data.id;
  return instance.put(url, data);
}

export const create = (data) => {
  const url = '/webvideo';
  return instance.post(url, data);
}

export const listNavigation = (data) => {
  const url = "/webvideo?page=" + data + "&limit=8";
  return instance.get(url);
};
