import { Row, Col, Divider } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { read } from "../../api/videoAPI";
import RandomVideo from "../../components/website/RandomVideo";

const WatchContainer = () => {
  const [video, setVideo] = useState([]);
  const [info, setInfo] = useState([]);
  const [theme, setTheme] = useOutletContext();

  let { id } = useParams();

  const getVideoDaXem = () => {
    const VideoDaXem = localStorage.getItem("videodaxem")
      ? JSON.parse(localStorage.getItem("videodaxem"))
      : [];
    return VideoDaXem;
  };

  const setVideoDaXem = (videodaxem) => {
    localStorage.setItem("videodaxem", JSON.stringify(videodaxem));
  };

  let videoOld = getVideoDaXem();

  useEffect(() => {
    read(id).then(({ data }) => {
      setVideo(...data);
      
      const newVideo = {...data};

      const exitsVideoOld = videoOld.find(element => element.id === newVideo[0].id);
      
      console.log("exitsVideoOld", exitsVideoOld);
      
      if (exitsVideoOld) {
        let videoFil = videoOld.filter(item => item.id != newVideo[0].id);
        videoOld = [...videoFil, ...data];
      }else{
        videoOld = [...videoOld, ...data]; 
      }
      setVideoDaXem(videoOld);
    });
  }, [id]);

  useEffect(() => {
    axios
      .get(
        `https://www.googleapis.com/youtube/v3/videos?key=AIzaSyBSTV5vxwXq5pXFa6_R3L26E4JZTzAZGyw&fields=items(snippet(title,tags,channelTitle,publishedAt),statistics(viewCount))&part=snippet,statistics&id=${video.idvideo}`
      )
      .then(({ data }) => setInfo(data.items));
  }, [video]);

  useEffect(() => {
    document.title = info && info.map((item) => item.snippet.title);
  }, [info]);

  if (video) {
    return (
      video && (
        <Row className="watch">
          <Col xs={24} sm={18} xl={18} className="watch-live">
            <iframe
              className="iframe-video"
              src={
                "https://www.youtube.com/embed/" + video.idvideo + "?autoplay=1"
              }
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div className={`watch-live-title ${theme ? "dark" : "light"}`}>
              <h3 className={`title-video-watch ${theme ? "dark" : "light"}`}>{info && info.map((item) => item.snippet.title)}</h3>
              <p>
                {info &&
                  info.map(
                    (item) =>
                      `${String(item.statistics.viewCount).replace(
                        /(.)(?=(\d{3})+$)/g,
                        "$1."
                      )} lượt xem - ${new Date(
                        item.snippet.publishedAt
                      ).toLocaleString("vi-VN", { timeZone: "Asia/Saigon" })}`
                  )}
              </p>
              <Divider orientation="left" className={`title-channel-watch ${theme ? "dark" : "light"}`}>
                {info && info.map((item) => item.snippet.channelTitle)}
              </Divider>
            </div>
          </Col>

          <Col xs={24} sm={6} xl={6}>
            <RandomVideo theme={theme} />
          </Col>
        </Row>
      )
    );
  }
  return <h1>Lỗi</h1>;
};

export default WatchContainer;
