import "../../css/menub.css";
import { Menu, Spin, Switch } from "antd";
import {
  AppstoreAddOutlined,
  CompassOutlined,
  DingtalkOutlined,
  HomeOutlined,
  MenuOutlined,
  NodeExpandOutlined,
  PlaySquareOutlined,
  PoundCircleOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { initTheme } from "../../recoil/theme";
import { useRecoilValue } from "recoil";

const { SubMenu } = Menu;

const MenuB = () => {
  const theme = useRecoilValue(initTheme);
  const handleClick = () => {
    document.querySelector(".menub").classList.toggle("active");
  };

  return (
    <Menu
      onClick={handleClick}
      style={{ minWidth: 100 }}
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      mode="inline"
      theme={theme ? "dark" : "light"}
    >
      <SubMenu key="sub1" icon={<MenuOutlined />} title="Menu">
        <Menu.Item key="1">
          <Link to="/">
            <HomeOutlined /> Trang chủ{" "}
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/search">
            <SearchOutlined /> Tìm kiếm{" "}
          </Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/watched">
            {" "}
            <CompassOutlined /> Video đã xem{" "}
          </Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/favorite">
            <PlaySquareOutlined /> Video yêu thích
          </Link>
        </Menu.Item>
        <Menu.Item className="title-menub" key="chinh">
            <AppstoreAddOutlined /> Dịch vụ khác
        </Menu.Item>
        <Menu.Item key="5">
          <Link to="/news">
            <NodeExpandOutlined /> Tin nhanh
          </Link>
        </Menu.Item>
        <Menu.Item key="6">
          <Link to="/coinmarket">
            <PoundCircleOutlined /> CoinMarket
          </Link>
        </Menu.Item>
        <Menu.Item key="7">
          <Link to="/nct">
            <DingtalkOutlined /> Nhaccuatui
          </Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default MenuB;
