import { Layout } from "antd";
const { Footer } = Layout;

const Foot = ({theme}) => {
  return (
    <Footer className={`${theme ? 'footer-dark' : "footer-light"}`}>
      <div className={`footer ${theme ? 'footer-dark' : "footer-light"}`} style={{display: 'flex', justifyContent: 'center', backgroundColor: '#fff'}}>
        <p>Copyright &copy; 2022 Created by Trần Tiến</p>
        {/* <img src="https://i.pinimg.com/originals/2c/ee/66/2cee66d1ec1d6235334e60a126528837.gif" style={{
          width: "200px",
          position: "absolute",
          top: "118px",
          left: "-31px",
          pointerEvents: "none"
        }} /> */}
      </div>
    </Footer>
  );
};

export default Foot;
