import { Layout, Switch, Menu } from "antd";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { checkDarkTheme } from "../commons/images/func/func";
import Foot from "../components/website/Foot";
import Head from "../components/website/Head";
import MenuB from "../components/website/MenuB";

import { SettingOutlined } from "@ant-design/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import { initInfoAdmin } from "../recoil/infoAdmin";
import { initTheme } from "../recoil/theme";
const { SubMenu } = Menu;
const { Sider, Content } = Layout;

const LayoutWebsite = () => {
  const [theme, setTheme] = useRecoilState(initTheme);
  const infoadmin = useRecoilValue(initInfoAdmin);
  useEffect(() => {
    checkDarkTheme() ? setTheme(true) : setTheme(false);
  }, []);

  const darkTheme = (checked) => {
    localStorage.setItem("darktheme", JSON.stringify(checked));
    setTheme(checked);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Head theme={theme} infoadmin={infoadmin.username} />
      <Layout>
        <Sider
          className={`menub ${theme ? "dark" : "light"}`}
          style={{ backgroundColor: "#FFFFFF", minHeight: "100vh" }}
        >
          <MenuB />
          <Menu mode="inline" theme={theme ? "dark" : "light"}>
            <SubMenu key="sub2" icon={<SettingOutlined />} title="Cài đặt">
              <Menu.Item key="8">
                <Switch onChange={darkTheme} checked={theme} /> Dark Theme
              </Menu.Item>
            </SubMenu>
          </Menu>
          <Foot theme={theme} />
        </Sider>
        <Content className={`${theme ? "dark" : "light"}`}>
          <Outlet context={[theme, setTheme]} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutWebsite;
