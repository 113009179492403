import { Route, Routes, useNavigate } from "react-router-dom";
import CoinMarket from "./components/website/CoinMarket";
import Nct from "./components/website/Nct";
import News from "./components/website/News";
import CpanelContainer from "./containers/cpanel/CpanelContainer";
import HomeContainer from "./containers/website/HomeContainer";
import WatchContainer from "./containers/website/WatchContainer";
import LayoutWatch from "./layouts/LayoutWatch";
import LayoutWebsite from "./layouts/LayoutWebsite";
import LayoutCpanel from "./layouts/LayoutCpanel";
import Add from "./containers/cpanel/Add";
import { create, listNavigation, update } from "./api/videoAPI";
import PrivateCpanel from "./components/cpanel/PrivateCpanel";
import Login from "./components/cpanel/Login";
import Edit from "./containers/cpanel/Edit";
import QueryPara from "./components/website/QueryPara";
import OldView from "./components/website/OldView";
import Error404 from "./components/website/Error404";
import { useSetRecoilState } from "recoil";
import { initInfoAdmin } from "./recoil/infoAdmin";
import { isAuth } from "./commons/images/auth/auth";
import { initLoading } from "./recoil/initLoading";
import { initDataAtom } from "./recoil/dataAtom";
import { useEffect } from "react";

const Router = () => {
    const setActive = useSetRecoilState(initLoading);
    const setData = useSetRecoilState(initDataAtom);
    const setInfoAdmin = useSetRecoilState(initInfoAdmin);

    useEffect(() => {
        listNavigation(1)
        .then(({ data }) => setData(data))
        .then(() => setTimeout(() => {setActive('')}, 2000));
        setInfoAdmin(isAuth()); 
    },[])

    let navigate = useNavigate();
    const onHandleAdd = (data) => {
        create({ ...data }).then(() => navigate("/cpanel", { replace: true }))
    }

    const onHandleEdit = (data) => {
        update({ ...data }).then(() => navigate("/cpanel", { replace: true }))
    }

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<LayoutWebsite />}>
                <Route index element={<HomeContainer />} />
                <Route path="/watched" element={<OldView title="Video đã xem | Metube" />} />
                <Route path="/favorite" element={<h2 style={{ textAlign: 'center' }}>Videos Yêu Thích</h2>} />
                <Route path="/news" element={<News />} />
                <Route path="/coinmarket" element={<CoinMarket />} />
                <Route path="/nct" element={<Nct />} />
                <Route path="/search" element={<QueryPara title="Tìm kiếm | Metube" />} />
                <Route path='*' element={<Error404 />} />
            </Route>

            <Route path="watch/*" element={<LayoutWatch />}>
                <Route index element={<h2>Fail</h2>} />
                <Route path=":id" element={<WatchContainer />} />
            </Route>

            <Route path="cpanel/*" element={<PrivateCpanel><LayoutCpanel /></PrivateCpanel>} >
                <Route index element={<CpanelContainer />} />
                <Route path="add" element={<Add onAdd={onHandleAdd} />} />
                <Route path="edit/:id" element={<Edit onEdit={onHandleEdit} />} />
            </Route>
        </Routes>
    )
}

export default Router;