import { Col, message, Row } from "antd";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { readid } from "../../api/videoAPI";

const Edit = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const onSubmit = (data) => {
    props.onEdit(data);
    message.success("Sửa thành công");
  };

  let { id } = useParams();

  useEffect(() => {
    readid(id).then(({ data }) => {
      reset(...data)
    });
  }, [id, reset]);

  return (
    <Row>
      <Col xs={24} xl={24}>
        <form
          className="form-add-cp"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gridRowGap: "1rem",
            marginTop: "1rem",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2>Edit Video</h2>
          <div className="mb-3">
            <input
              width="100%"
              type="text"
              className="form-control input-form"
              placeholder="Tên video"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="err-input">* Vui lòng nhập tên danh mục</p>
            )}
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control input-form"
              placeholder="ID video"
              {...register("idvideo", { required: true })}
            />
            {errors.idvideo && <p className="err-input">* Vui lòng nhập ID</p>}
          </div>
          <button type="submit" className="btn btn-primary">
            Sửa
          </button>
        </form>
      </Col>
    </Row>
  );
};

export default Edit;
