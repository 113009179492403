import { Col, message, Row } from "antd";
import { useForm } from "react-hook-form";

const Add = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    props.onAdd(data);
    message.success("Thêm thành công");
  };
  return (
    <Row>
      <Col xs={24} xl={24}>
        <form
          className="form-add-cp"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gridRowGap: "1rem",
            marginTop: "1rem",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2>Thêm Video</h2>
          <div className="mb-3">
            <input
              width="100%"
              type="text"
              className="form-control input-form"
              placeholder="Tên video"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <p className="err-input">* Vui lòng nhập tên danh mục</p>
            )}
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control input-form"
              placeholder="ID video"
              {...register("idvideo", { required: true })}
            />
            {errors.idvideo && <p className="err-input">* Vui lòng nhập ID</p>}
          </div>
          <button type="submit" className="btn btn-primary">
            Thêm
          </button>
        </form>
      </Col>
    </Row>
  );
};

export default Add;
