import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Empty, message, Pagination, Popover, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { checkDarkTheme, getTitle } from "../../commons/images/func/func";

const OldView = ({ title }) => {
  const [data, setData] = useState([]);
  const [theme, setTheme] = useState(checkDarkTheme());

  useEffect(() => {
    checkDarkTheme() ? setTheme(true) : setTheme(false);
  }, []);

  useEffect(() => {
    getTitle(title);
  });

  useEffect(() => {
    localStorage.getItem("videodaxem") &&
      setData(JSON.parse(localStorage.getItem("videodaxem")).reverse());
  }, []);

  const onChange = (data) => {
    console.log(data);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    localStorage.removeItem("videodaxem");
    setData([]);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const removeOne = (id) => {
    setData(data.filter((item) => item.id != id));
    localStorage.setItem(
      "videodaxem",
      JSON.stringify(data.filter((item) => item.id != id))
    );
  };

  const favoriteOne =(id) => {
    message.warning('Chức năng đang được xây dựng! =>'+id)
  }

  const handleVisibleChange = (visible) => {
    // setState({ visible });
  };

  return (
    <div style={{ margin: 10 }}>
      <Divider orientation="center" className={`title-oldview ${theme ? "dark" : "light"}`}>Video đã xem</Divider>
      {data.length > 0 && (
        <div>
          <Button
            type="primary"
            onClick={showModal}
            danger
            style={{ margin: 10 }}
          >
            Dọn dẹp
          </Button>
          <Modal
            title="Cảnh báo"
            visible={isModalVisible}
            onCancel={handleCancel}
            onOk={handleOk}
          >
            <p>Bạn có chắc chắn muốn xóa tất cả video đã xem?</p>
          </Modal>
        </div>
      )}
      {data.length <= 0 && <Empty />}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {data &&
          data.map((item) => (
            <Col
              key={item.id}
              className="gutter-row"
              xs={24}
              sm={12}
              md={8}
              xl={6}
            >
              <Link to={"/watch/" + item.idvideo}>
                <img
                  width="100%"
                  src={
                    "https://i.ytimg.com/vi/" + item.idvideo + "/hqdefault.jpg"
                  }
                  alt=""
                />
              </Link>
              <div className="title-big hover-presently">
                <h4 className={`title-video-home ${theme ? "dark" : "light"}`}>{item.name}</h4>
                <Popover
                  content={
                    <div className="favorite-big">
                      <a onClick={() => removeOne(item.id)}>Xóa</a>
                      <a onClick={() => favoriteOne(item.id)}>Yêu thích</a>
                    </div>
                  }
                  title="Chức năng"
                  trigger="click"
                  onVisibleChange={handleVisibleChange}
                >
                  <EllipsisOutlined className="icon-more" />
                </Popover>
              </div>
            </Col>
          ))}
      </Row>
      {data.length > 10 && (
        <Pagination
          style={{ textAlign: "center" }}
          onChange={onChange}
          total={data.length}
        />
      )}
    </div>
  );
};

export default OldView;
