import Router from "./routes";
import "./css/responsive.css";
import "./css/night-mode.css";

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
